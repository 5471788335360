<template>
    <main-template>
        <template slot-scope="{logo}">
        <fetch-siren-root :linkName='linkName' :link="link">

            <div slot-scope="{ response }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <template v-if="hasLink(response,'main-parent-category')">
                            <span @click="navigateToMainParentCategory(response)" class="cursor-pointer">
                            {{ response.body.properties.get('parent_category_name') }}
                            </span>
                        </template>
                        <template v-else>
                            <span @click="navigateToProductCategories(response)" class="cursor-pointer">
                                Product Library
                            </span>
                        </template>
                        <template v-if="response.body.properties.get('name') !== 'default-no-category'">
                            <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <span @click="navigateToParentCategory(response)" class="cursor-pointer">
                                {{response.body.properties.get('name')}}
                            </span>
                        </template>
                        <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <span class="text-secondary-darkblue">
                            Products
                        </span>

                    </page-header>
                    <product-list-paginated-list :response="response"></product-list-paginated-list>

                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import Actions from "@/v2/components/Actions";
import Button from "@/v2/buttons/Button";
import DownloadPdf from "@/pages/DownloadPdf.vue";
import FetchSirenRoot from "@/components/FetchSirenRoot";
import Loading from "@/components/Loading";
import MainTemplate from "@/v2/templates/MainTemplate";
import PageHeader from "@/v2/components/Header";
import ProductCategoryList from "@/components/ProductCategoryList.vue";
import ProductListPaginatedList from "@/v2/paginatedLists/ProductListPaginatedList.vue";
import SubCategoryPaginatedList from "@/v2/paginatedLists/SubCategoryPaginatedList.vue";
export default {
    name: "ProductsWithoutType",
    data() {
        return {
            displaySupplierDetails: false,
            showDataSheet: false
        }
    },
    components: {
        ProductListPaginatedList,
        ProductCategoryList,
        SubCategoryPaginatedList,
        Actions,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        DownloadPdf
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToProductCategories(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('product-categories')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        navigateToParentCategory(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('parent-category')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        navigateToMainParentCategory(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('main-parent-category')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        hasLink(response, linkName){
            return response.body.links.filter(link => link.rels.contains(linkName)).first();
        },
        getDeletionPath(response){
            if(this.hasLink(response, 'parent-category')){
                let selfLink = response.body.links.filter(link => link.rels.contains('parent-category')).first().href;
                let parts = selfLink.split('/api');
                return parts[1];
            }
            let selfLink = response.body.links.filter(link => link.rels.contains('product-categories')).first().href
            let parts = selfLink.split('/api');
            return parts[1];
        }
    }
}
</script>

<style scoped>

</style>
